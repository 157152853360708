import React, { Component } from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap"
import mail from "../images/mail.svg"
import padlockClosed from "../images/padlock-closed.svg"
import axios from "axios"
import { ALERT_MSG_ERROR, AUTH_TOKEN_KEY, translate, URL_LOGIN,KEY_EMAIL,URL_IS_AUTH } from "../utils"
import ModalAlert from "../components/modalAlert"

class Login extends Component {


  componentDidMount() {
    this.setState({
      placeholderEmail:translate('E-mail'),
      placeholderPassword: translate("wachtwoord"),
    })
    if(sessionStorage.getItem(KEY_EMAIL)){
      this.state.email=sessionStorage.getItem(KEY_EMAIL)
    }

  }

  constructor(props){
    super(props)
    this.state = {
      email: '',
      password: '',
      showAlert: false,
      alertTitle: '',
      alertMessage: '',
      placeholderEmail:'',
      placeholderPassword: '',
      saveM:false,
    }
    if (typeof window !== 'undefined') {
    if(localStorage.getItem(AUTH_TOKEN_KEY))
    this.auth();
    }

  }


   auth = () => {
    const token = sessionStorage.getItem(AUTH_TOKEN_KEY)
    const data = {
      headers: { Authorization: `Bearer ${token}` },
    }
    const url = URL_IS_AUTH
    axios
      .get(url, data)
      .then(res => {
        if (res.data) {
          window.location.href = "/feed"
        }
      })
      .catch(err => {
        if (err.response) {
          console.log(err.response.data)
        }
        console.log(err)
      })
  }



  saveMe =(e)=>{
    this.setState({ saveM: e.target.checked});
  }


  render() {
    const {email,password,showAlert,alertMessage,alertTitle,placeholderEmail,placeholderPassword} = this.state;

    const pressSignIn = (e) => {
      e.preventDefault();
      const data = {
        email: email,
        password: password,
      }
      axios.post(URL_LOGIN,data)
        .then(res => {
          localStorage.removeItem(AUTH_TOKEN_KEY);
          console.log('TOKEN :',res.data)
          localStorage.setItem(AUTH_TOKEN_KEY,res.data)


          if(sessionStorage.getItem("lastURL")){
            let lastURL=sessionStorage.getItem("lastURL")
            sessionStorage.removeItem('lastURL');
            window.location.href = lastURL
          } else {
            window.location.href = '/feed';
          }
          

          if(this.state.saveM) {sessionStorage.setItem(KEY_EMAIL,data.email)}
        })
        .catch(err => {
          if (err.response && err.response.data) {
            this.setState({
              showAlert: true,
              alertTitle: ALERT_MSG_ERROR,
            })
            if (err.response.data === "failed to find user by email") {
              this.setState({alertMessage: translate("failed to find user by email")})
            } else if (err.response.data === 'invalid credentials') {
              this.setState({alertMessage: translate("invalid credentials")})
            }else this.setState({alertMessage: err.response.data.message})
          }
          console.log(err)
        })
    }



    return (
      <Layout withBg>
        <div className="formWrapper">
          <Container>
            <Row className="justify-content-center">
              <Col md="12" lg="6" xl="4">
                <div className="text-left">
                  <h1 className="mb-3 h1-signupin-yellow">{translate('aanmelden')}</h1>
                  <Form onSubmit={(e) => pressSignIn(e)}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <img
                            width={16}
                            height={8}
                            className="img-fluid"
                            src={mail}
                            alt=""
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        value={email}
                        onChange={(e) => this.setState({email: e.target.value.replace('é','e').replace('è','e').replace(' ','').toLowerCase()})}

                        placeholder={placeholderEmail}
                        aria-label=""
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          <img
                            width={11}
                            height={17}
                            className="img-fluid"
                            src={padlockClosed}
                            alt=""
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type={'password'}
                        value={password}
                        onChange={(e) => this.setState({password: e.target.value})}
                        placeholder={placeholderPassword}
                        aria-label=""
                        aria-describedby="basic-addon1"
                      />
                    </InputGroup>
                    <div className="d-flex justify-content-center">
                      <Form.Text className="text-muted">
                        {translate('wachtwoord vegeten? Klik')}{" "}
                        <a href={'/recover'} className="text-link">
                          {translate('hier')}
                        </a>{" "}
                        {translate('om te herstellen',false)+"."}
                      </Form.Text>
                    </div>
                    <Form.Group
                      controlId="formBasicCheckbox"
                      onClick={this.saveMe}
                      className="d-flex justify-content-center mt-3"
                    >
                      <Form.Check
                        className="text-certer"
                        type="checkbox"
                        label={translate("onthoud mij",false)}
                      />
                    </Form.Group>
                    <div style={{display: 'flex',justifyContent: 'center'}}>
                      <Button variant="success" type="submit" /*onClick={() => pressSignIn()}*/ className="button-yellow">
                        {translate('aanmelden')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {(showAlert)?
          <ModalAlert
            alertTitle={alertTitle}
            alertMessage={alertMessage}
            closeModal={() => this.setState({showAlert: false})}
          />:null
        }
      </Layout>
    )
  }
}

export default Login
